import {
  type ButtonProps,
  HStack,
  Image,
  Text,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react';
import { NextChakraButton } from 'components/wrappers';
import type { Menu } from 'configs/menus';
import { MenuDrawer } from './MenuDrawer';
import { useRouter } from 'next/router';

export const MenuItem: React.FC<
  Menu & { fontSize: ButtonProps['fontSize'] }
> = ({
  url,
  title,
  child_items,
  file,
  icon,
  fontSize = 'lg',
  isChild,
  showSubMenu,
  setShowSubMenu
}) => {
  const { push } = useRouter();
  const { isOpen, onToggle } = useDisclosure();
  const hasChildItems = Boolean(child_items);
  const href = url ?? '/';
  const openDrawer = useBreakpointValue({
    base: false,
    lg: true,
    xl: false
  });

  const isExternal = icon?.includes('external');

  return (
    <>
      <HStack>
        <NextChakraButton
          onClick={() => {
            openDrawer && hasChildItems
              ? onToggle()
              : hasChildItems
              ? setShowSubMenu(showSubMenu)
              : isExternal
              ? push(href)
              : null;
          }}
          hasChildItems={hasChildItems}
          href={href}
          display={'inline-flex'}
          textAlign={{ base: 'center', xl: 'start' }}
          justifyContent={{ base: 'center', xl: 'flex-start' }}
          width={'fit-content'}
          borderRadius={'3xl'}
          variant='unstyled'
          fontWeight={'normal'}
          flexShrink={0}
          color={'dark.600'}
          bgColor={'transparent'}
          fontSize={fontSize}
          pr={{ base: 4, lg: 0, xl: 4 }}
          pl={{ base: 4, lg: 0, xl: 4 }}
          mb='1'
          _hover={{
            bgColor: 'light.500'
          }}
          sx={{
            '&.active': {
              color: 'black',
              bgColor: 'light.500'
            },
            span: {
              marginInlineEnd: { lg: isChild ? '0.5rem' : '0rem', xl: '0.5rem' }
            }
          }}
        >
          <HStack gap={2} alignItems='center'>
            {file && typeof file === 'string' ? (
              <Image src={file} w={5} h={5} display='inline' />
            ) : (
              <Text>{icon}</Text>
            )}
            <Text
              as='span'
              display={{
                base: 'inline',
                lg: isChild ? 'initial' : 'none',
                xl: 'inline'
              }}
            >
              {title}
            </Text>
          </HStack>
        </NextChakraButton>
      </HStack>

      {isOpen && (
        <MenuDrawer
          isOpen={isOpen}
          onToggle={onToggle}
          items={child_items}
          title={title}
        />
      )}
    </>
  );
};

import { Box, VStack } from '@chakra-ui/react';
import { useMenu, useTranslation } from 'hooks';
import { useSession } from 'next-auth/react';

import { useState } from 'react';
import { AuthBtn } from './AuthBtn';
import { MenuItem } from './MenuItem';

import { isNewsDomain, isEnglishDomain } from 'configs';

const MainMenu: React.FC = () => {
  const headerMenu = useMenu('header');
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [parentId, setParenId] = useState('');
  const { data: session, status } = useSession();
  const { t } = useTranslation();

  return (
    <>
      <Box
        pos={'sticky'}
        mt='4'
        justifyContent={{ base: 'initial', lg: 'center' }}
        display={{ base: 'inline-flex', lg: 'flex', xl: 'flex' }}
        width={{ base: '65%', lg: '100%', xl: '100%' }}
        as='nav'
        transform='translateX(0%)'
        minH='100vh'
      >
        <Box overflow={{ base: 'initial', md: 'hidden' }} position='relative'>
          {headerMenu?.map((item, index) => {
            const isLastItem = index === headerMenu.length - 1;

            return (
              <Box key={index}>
                <Box
                  mb='2'
                  pos='relative'
                  onClick={() => {
                    setParenId(item.id);
                  }}
                  transform={
                    showSubMenu ? 'translateX(110%)' : 'translateX(0%)'
                  }
                  opacity={showSubMenu ? '0' : '1'}
                  visibility={showSubMenu ? 'hidden' : 'visible'}
                  transition='transform 0.4s, opacity 0.4s, visibility 0.4s'
                  overflow={'hidden'}
                >
                  <MenuItem
                    {...item}
                    key={item.id}
                    showSubMenu={true}
                    setShowSubMenu={setShowSubMenu}
                    fontSize={'md'}
                    isChild={false}
                  />

                  {index === 0 && session && (
                    <MenuItem
                      {...item}
                      url='/bookmarks'
                      title={t('bio.my_magazin')}
                      icon={' 📚'}
                      key={item.id}
                      showSubMenu={true}
                      setShowSubMenu={setShowSubMenu}
                      isChild={false}
                    />
                  )}
                  {isLastItem && !isEnglishDomain && !isNewsDomain && (
                    <Box display={'flex'} mt='2'>
                      <AuthBtn />
                    </Box>
                  )}
                </Box>
                <Box
                  className='subMenu'
                  pos={'absolute'}
                  top='0'
                  width={'100%'}
                  transform={
                    showSubMenu ? 'translateX(0%)' : 'translateX(-110%)'
                  }
                  opacity={showSubMenu ? '1' : '0'}
                  visibility={showSubMenu ? 'visible' : 'hidden'}
                  transition='transform 0.4s, opacity 0.4s, visibility 0.4s'
                >
                  {parentId === item.id && (
                    <MenuItem
                      {...item}
                      showSubMenu={false}
                      setShowSubMenu={setShowSubMenu}
                      isChild={false}
                    />
                  )}
                  <VStack
                    mt='4'
                    align={'stretch'}
                    ml={4}
                    borderInlineStartWidth={2}
                    borderInlineStartColor='light.500'
                    overflow={{ base: 'none', lg: 'scroll', xl: 'scroll' }}
                    overflowX='hidden'
                    maxH={{ base: 'none', md: 'none', lg: '65vh', xl: '65vh' }}
                    sx={{
                      '&::-webkit-scrollbar': {
                        width: 1
                      },
                      '&::-webkit-scrollbar-track': {
                        width: 1
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: 'var(--arageek-colors-dark-300)',
                        borderRadius: 'md'
                      }
                    }}
                  >
                    {Array.isArray(item?.child_items) &&
                      parentId === item.id &&
                      item?.child_items?.map((child, index) => {
                        return (
                          <MenuItem
                            key={child.id}
                            {...child}
                            fontSize='md'
                            isChild={true}
                          />
                        );
                      })}
                    {Array.isArray(item?.child_items) &&
                      !showSubMenu &&
                      item?.child_items?.map((child, index) => {
                        return (
                          <Box display={'none'} key={child.id}>
                            <MenuItem {...child} fontSize='md' isChild={true} />
                          </Box>
                        );
                      })}
                  </VStack>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default MainMenu;
